<template>
  <div class="row books">
    <div class="col-md-12 mt-2">
      <div class="card p-2">
        <h4 class="h3 text-center">Bundle Summary</h4>
        <b-row v-if="isError" class="justify-content-center mt-2">
          <b-col lg="10">
            <b-alert variant="danger" show dismissible>
              Opps .. Something is wrong
            </b-alert>
          </b-col>
        </b-row>
        <div class="card-body">
          <div class="form-group row">
            <span class="col-sm-2">Select Range</span>
            <div class="col-md-8">
              <v-date-picker
                v-model="range"
                mode="date"
                :masks="masks"
                is-range
                :max-date="new Date()"
              >
                <template v-slot="{ inputValue, inputEvents, isDragging }">
                  <div class="rows">
                    <input
                      class="flex-grow pl-8 pr-2 py-1 bg-gray-100 border rounded w-full p-1"
                      :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                      :value="inputValue.start"
                      v-on="inputEvents.start"
                      :max-date="new Date()"
                    />

                    <span class="p-2">to</span>

                    <input
                      class="flex-grow pl-8 pr-2 py-1 bg-gray-100 border rounded w-full p-1"
                      :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                      :value="inputValue.end"
                      v-on="inputEvents.end"
                      :max-date="new Date()"
                    />
                  </div>
                </template>
              </v-date-picker>
            </div>
            <div class="col-md-2">
              <button class="btn btn-block btn-primary" @click="actionSearch">
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
          <div class="row justify-content-end">
            <div class="col-md-2 mb-1">
              <input
                type="text"
                v-model="curs"
                class="form-control"
                placeholder="14000"
              />
            </div>
            <div class="col-md-1 mb-1">
              <button
                class="btn btn-block btn-outline-danger"
                @click="actionCreateTablePDF"
              >
                <i class="fa fa-file-pdf-o"></i>
              </button>
            </div>
            <div class="col-md-1 mb-1">
              <button
                class="btn btn-block btn-outline-success"
                @click="actionDownloadRecap"
              >
                <i class="fa fa-file-excel-o" aria-hidden="true"></i>
              </button>
            </div>
            <div class="col-md-2">
              <label for="" class="col-sm-auto">Count </label>
              <strong class="col-sm-auto">
                {{ formatPriceBasic(totalRows) }}
              </strong>
            </div>
          </div>
          <TableSummary 
            :items="items" 
            :curs="curs" 
            :totalAmount="totalAmount" 
            :totalAmountD="totalAmountD" 
            :totalAmountPTS="totalAmountPTS"
            :isLoading="isLoading"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import PDFReports from "../../plugins/PDFReports"
import Xlsreport from "../../plugins/Xlsreport";
import { mapActions } from "vuex";
import TableSummary from "./tablesummary.vue"
export default {
  name: "BundleSummary",
  components: {
    TableSummary
  },
  data() {
    return {
      currentPage: 1,
      perPage: 100,
      totalRows: 0,
      totalAmount: 0,
      totalAmountD: 0,
      totalAmountPTS: 0,
      curs: 14200,
      items: [],
      Dates: {
        from: moment().format("YYYY,MM,DD"),
        to: moment().format("YYYY,MM,DD"),
      },
      range: {
        start: moment().format("YYYY,MM,DD"),
        end: moment().format("YYYY,MM,DD"),
      },
      masks: {
        input: "YYYY-MM-DD",
      },
      isLoading: false,
      isError: false,
    };
  },
  watch: {
    currentPage: function() {
      this.actionSearch();
    },
  },
  methods: {
    ...mapActions("bundle", ["fetchReportBundle"]),
    formatPrice(value, currency) {
      if (value) {
        var formatter = null;
        if (currency == "USD") {
          formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: currency,
          });
        } else {
          formatter = new Intl.NumberFormat("en-ID", {
            style: "currency",
            currency: currency,
          });
        }

        return formatter.format(value);
      }

      return "0";
    },
    formatDate(tgl) {
      //return moment(tgl).format("DD MMM YY hh:mm");
      return new Date(tgl).toLocaleString();
    },
    customLabel({ name }) {
      if (name) {
        return name;
      } else {
        return "Type Here";
      }
    },
    actionSearch() {
      let payload = {
        from: moment(this.range.start).format("YYYY-MM-DD"),
        to: moment(this.range.end).format("YYYY-MM-DD"),
        page: this.currentPage,
        limit: this.perPage,
      };
      try {
        this.isLoading = true;
        this.fetchReportBundle(payload).then((response) => {
          let res = response.data.data;
          this.items = response.data.data;
          let t = res.map((e) => {
            if (e.currency_code == "IDR") {
              return parseInt(e.amount);
            }
            return 0;
          });
          let pts = res.map((e) => {
            if (e.currency_code == "PTS") {
              return parseInt(e.amount);
            }
            return 0;
          });
          let td = res.map((e) => {
            if (e.currency_code == "USD") {
              return parseFloat(e.amount);
            }else {
              return 0
            }
          });
          let total = res.length
          if (total != 0) {
            this.totalRows = total
            this.totalAmount = t.reduce((a, b) => a + b);
            this.totalAmountD = td.length > 0 ? td.reduce((a, b) => a + b): 0;
            this.totalAmountPTS = pts.length > 0 ? pts.reduce((a, b) => a + b) : 0;
            this.isLoading = false;
          } else {
            this.isLoading = false;
          }
        });
      } catch (error) {
        console.log(error);
        this.isError = true;
        this.isLoading = false;
      }
    },
    formatPriceBasic(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    setBodyData() {
      let datatable = [];
      let n = 0;
      this.items.forEach((e) => {
        let  price=
            e.currency_code == "IDR"
              ? this.formatPrice(e.amount, e.currency_code)
              : 0
        let price_usd =e.currency_code == "USD"? this.formatPrice(e.amount, e.currency_code): 0
        let price_pts = e.currency_code == "PTS" ? this.formatPrice(e.amount, e.currency_code): 0
        let datain =[
          {content: ++n},
          {content: e.name},
          {content: e.count},
          {content: price},
          {content: price_usd},
          {content: price_pts},
        ]
        datatable.push(datain)
      });
      return datatable
    },
    setFooter(){
      let datatable = []
      let span = [{
        content: 'Total',
        colSpan: 3,
        styles: { halign: 'center',fontStyle:'bold' }
      },
      
      { content: this.formatPrice(this.totalAmount,"IDR"),styles: { halign: 'right' }},
      { content: this.formatPrice(this.totalAmountD * this.curs, "IDR"),styles: { halign: 'right' }},
      { content: this.formatPrice(this.totalAmountPTS,"IDR"),styles: { halign: 'right' }}
      ]
      datatable.push(span)
      return datatable
    },
    setTitleHead(){
      let head =["No","Name","Count","Price IDR","Price USD","Price Point",
        // "Gross Profit Margin","Gateway Fee","Gateway Cost","Balance"
      ]
      return head
    },
    actionCreateTablePDF() {
      let payload = {
        from: this.range.start,
        to: this.range.end
      };
      let data = this.setBodyData()
      let footer = this.setFooter()
      let datatable = data.concat(footer)
      let report = new PDFReports(``, payload,this.curs,`${moment(payload.from).unix()}`,'-')
      
      report.SetHead(this.setTitleHead())
      report.SetData(datatable)
      report.BuildData()
      window.location.reload()
    },
    setBodyXlsx(){
      let parseData = []
      let Attributes = [
          "No",
          "Offer Name",
          "Count",
          "Price IDR",
          "Price USD",
          "Price POINT",
        ];
        parseData.push(Attributes);
        let n = 0;
        this.items.forEach((e) => {
          let sales = [
            ++n,
            e.name,
            e.count,
            e.currency_code == "IDR" ? parseInt(e.amount) : '-',
            e.currency_code == "USD" ? parseFloat(e.amount) : '-',
            e.currency_code == "PTS" ? parseInt(e.amount) : '-',
          ];
          parseData.push(sales);
        });
      return parseData
    },
    actionDownloadRecap() {
     
      let data = this.items;
      if (data.length < 1) {
        this.$notify({
          group: "foo",
          type: "error",
          title: "Opss .. ",
          text: "Tidak ada data yang di rekap",
        });
      } else {
        let payload = {
          from: moment(this.range.start).format("YYYY-MM-DD"),
          to: moment(this.range.end).format("YYYY-MM-DD"),
        };
        let title = "SINGLE"+ payload.from + " - " + payload.to
        let newXlsx = new Xlsreport(title,payload,this.curs,title,"")
        let body = this.setBodyXlsx()
        newXlsx.setBody(body)
        newXlsx.BuildReport()
       
      }
    },
  },
};
</script>
<style scoped>
.books {
  font-family:"Avenir Next" ,"effra", Helvetica, Arial, sans-serif;
}
</style>
