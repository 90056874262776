<template>
  <div class="table-responsive">
    <table
      class="table table-striped table-bordered table-sm table-responsive-sm mt-4"
    >
      <thead>
        <tr>
          <th>No</th>
          <th>Offers Name</th>
          <th>Count</th>
          <th>Price IDR</th>
          <th>Price USD</th>
          <th>Price POINT</th>
        </tr>
      </thead>
      <tbody v-if="!isLoading">
        <template v-for="(item, index) in items">
          <tr :key="index">
            <td>{{ ++index }}</td>
            <td>{{ item.name }}</td>
            <td class="text-center">{{ item.count }}</td>
            <td
              v-if="item.currency_code == 'IDR'"
              class="text-right"
              style="width:15%"
            >
              {{ formatPrice(item.amount, item.currency_code) }}
            </td>
            <td v-else></td>
            <td
              v-if="item.currency_code == 'USD'"
              class="text-right"
              style="width:20%"
            >
              {{ formatPrice(item.amount, item.currency_code) }}
            </td>
            <td v-else></td>
            <td
              v-if="item.currency_code == 'PTS'"
              class="text-right"
              style="width:15%"
            >
              {{ formatPrice(item.amount, item.currency_code) }}
            </td>
            <td v-else></td>
          </tr>
        </template>
      </tbody>
      <tbody v-else>
        <template>
          <tr>
            <td colspan="10">
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong> Loading...</strong>
              </div>
            </td>
          </tr>
        </template>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="3" class="text-center">
            <b>Total</b>
          </td>
          <td class="text-right">
            {{ formatPrice(totalAmount, "IDR") }}
          </td>
          <td class="text-right">
            {{ formatPrice(totalAmountD, "USD") }}
            ( {{ formatPrice(totalAmountD * curs, "IDR") }} )
          </td>
          <td class="text-right">
            {{ formatPrice(totalAmountPTS, "IDR") }}
          </td>
        </tr>
        <tr>
          <td colspan="4" class="text-center">
            <b> Total </b>
          </td>
          <td colspan="2" class="text-right">
            <strong>
              {{
                formatPrice(
                  totalAmount + totalAmountD * curs + totalAmountPTS,
                  "IDR"
                )
              }}
            </strong>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
  export default {
    name: 'TableSummary',
    props: {
      items: {
        type: Array,
        required: true
      },
      totalAmount: {
        type: Number,
        required: true
      },
      totalAmountD: {
        type: Number,
        required: true
      },
      totalAmountPTS: {
        type: Number,
        required: true
      },
      curs: {
        type: Number,
        required: true
      },
      isLoading: {
        type: Boolean,
        required: true
      }
    },
    methods: {
      formatPrice(value, currency) {
        if (value) {
          var formatter = null;
          if (currency == "USD") {
            formatter = new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: currency,
            });
          } else {
            formatter = new Intl.NumberFormat("en-ID", {
              style: "currency",
              currency: currency,
            });
          }

          return formatter.format(value);
        }

        return "0";
      },
    }
  }
</script>

<style lang="scss" scoped>

</style>