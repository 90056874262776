var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-responsive"
  }, [_c('table', {
    staticClass: "table table-striped table-bordered table-sm table-responsive-sm mt-4"
  }, [_vm._m(0), !_vm.isLoading ? _c('tbody', [_vm._l(_vm.items, function (item, index) {
    return [_c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(++index))]), _c('td', [_vm._v(_vm._s(item.name))]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(item.count))]), item.currency_code == 'IDR' ? _c('td', {
      staticClass: "text-right",
      staticStyle: {
        "width": "15%"
      }
    }, [_vm._v(" " + _vm._s(_vm.formatPrice(item.amount, item.currency_code)) + " ")]) : _c('td'), item.currency_code == 'USD' ? _c('td', {
      staticClass: "text-right",
      staticStyle: {
        "width": "20%"
      }
    }, [_vm._v(" " + _vm._s(_vm.formatPrice(item.amount, item.currency_code)) + " ")]) : _c('td'), item.currency_code == 'PTS' ? _c('td', {
      staticClass: "text-right",
      staticStyle: {
        "width": "15%"
      }
    }, [_vm._v(" " + _vm._s(_vm.formatPrice(item.amount, item.currency_code)) + " ")]) : _c('td')])];
  })], 2) : _c('tbody', [[_c('tr', [_c('td', {
    attrs: {
      "colspan": "10"
    }
  }, [_c('div', {
    staticClass: "text-center text-danger my-2"
  }, [_c('b-spinner', {
    staticClass: "align-middle"
  }), _c('strong', [_vm._v(" Loading...")])], 1)])])]], 2), _c('tfoot', [_c('tr', [_vm._m(1), _c('td', {
    staticClass: "text-right"
  }, [_vm._v(" " + _vm._s(_vm.formatPrice(_vm.totalAmount, "IDR")) + " ")]), _c('td', {
    staticClass: "text-right"
  }, [_vm._v(" " + _vm._s(_vm.formatPrice(_vm.totalAmountD, "USD")) + " ( " + _vm._s(_vm.formatPrice(_vm.totalAmountD * _vm.curs, "IDR")) + " ) ")]), _c('td', {
    staticClass: "text-right"
  }, [_vm._v(" " + _vm._s(_vm.formatPrice(_vm.totalAmountPTS, "IDR")) + " ")])]), _c('tr', [_vm._m(2), _c('td', {
    staticClass: "text-right",
    attrs: {
      "colspan": "2"
    }
  }, [_c('strong', [_vm._v(" " + _vm._s(_vm.formatPrice(_vm.totalAmount + _vm.totalAmountD * _vm.curs + _vm.totalAmountPTS, "IDR")) + " ")])])])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th', [_vm._v("No")]), _c('th', [_vm._v("Offers Name")]), _c('th', [_vm._v("Count")]), _c('th', [_vm._v("Price IDR")]), _c('th', [_vm._v("Price USD")]), _c('th', [_vm._v("Price POINT")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "3"
    }
  }, [_c('b', [_vm._v("Total")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "4"
    }
  }, [_c('b', [_vm._v(" Total ")])]);
}]

export { render, staticRenderFns }